/*
 * Navbar css File
*/
@media only screen and (max-width: 1200px) {
  .nav-menu {
      display: none;
  }
  .logo-container {
      img {
          width: 25% !important;
          margin: 2% 20% !important;
          // margin-top: 2% !important;
          // float: right !important;
          // text-align: center;
          // margin-left: 20% !important;
        }
  }
}
#nav {
  margin: 0;
}
.nav-bar {
  margin: 3em 0;
  position: absolute;
  width: 100%;
  // bottom: 70%;
  z-index: 10;
  .logo-container {
    width: 100%;
    img {
      float: left;
      position: relative;
      margin-left: 4%;
      width: 20%;
      // margin: 0 5em;
      // width: 250px;
    }
  }
  .nav-menu {
    float: right;
    margin: 0 3em;
  }
  .nav-links {
    float: right;
    li {
      margin: 15px;
      display: inline-flex;
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      .selected {
        border-bottom: 3px solid springgreen;
      }
      a {
        color: #fff;
        text-decoration: none;
        &:hover {
          color: springgreen;
          transition: all 200ms ease-in-out;
        }
      }
    }
  }

}
.MuiList-root{
    .menu-links, a, a:hover{
        color: #000000;
        text-decoration: none;
    }
}







// .nav-modal{
//     text-align: left;
//     background-color: #fff;
//     position: absolute;
//     padding-left: 0;
//     margin: 3em;
//     width: 280px;
//     z-index: 2;
//     border-bottom: 5px solid #42c6d1;
//     border-bottom-right-radius: 20px;
//     border-bottom-left-radius: 20px;
//     border-top-right-radius: 20px;
//     border-top-left-radius: 20px;
//     padding-top: 15px;
//     padding-bottom: 15px;
//     transition: all 800ms ease-in-out;
//     li{
//         a {
//             color: #000;
//             // font-weight: bold;
//             display: block;           
//             padding: 10px;
//             text-decoration: none;
//         }
//     }
//     li:hover {
//         background-color: lightblue;
//     }
// }
// #nav-main-modal{
//     right: 7em;
// }
// #nav-sub-modal{
//     right:-170px;
// }
