/*
 * Cards css File
*/
// @media only screen and (max-width: 992px) {
//     #footer .card {
//       height: auto;
//     }
//   }
// Specific Styles
#card-set{
    // Generic Styles
    margin-top: 40%;
    // display: inline-flex;
    // position: relative;
    // z-index: 0;
    .blue-border{
        border: 5px solid #325eab;
    }
    .purple-border{
        border: 5px solid #8b4d9f;
    }
    .green-border{
        border: 5px solid #11adad;
    }

    // Specific Styles
    .card{
        background-color: #FFFFFF;
        height: 90%;
        // width: 20em;
        padding: 1em;
        border-radius: 40px;
        margin: 1em;
        box-shadow: 1px 1px 10px #969696;
        h3{
            // font-size: 1.5em;
            font-weight: bold;
            // margin-top: 1em;
        }
    }
}