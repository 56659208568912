/*
 * Footer css File
*/
@media only screen and (max-width: 991px) {
  #footer #logo {
    // margin: 2em auto;
      width: 50%;
  }
}
@media only screen and (max-width: 576px) {
  #footer #logo {
    margin: 2em auto;
      width: 50%;
  }
}
#footer {
  /* propertys here */
  margin-top: 2em;
  .rotateme {
    -webkit-animation-name: rotateme;
    animation-name: rotateme;
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
  .rotatemeanticlock {
    -webkit-animation-name: rotatemeanticlock;
    animation-name: rotatemeanticlock;
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
  .capital-logo {
    float: left;
    margin-bottom: 2em;
    width: 100%;
  }
  .bg-footer {
    padding: 30px 15px;
    background-color: #f7fafd;
    color: #333;
    position: relative;
  }
  .animate-shape1 {
    position: absolute;
    top: 100px;
    left: 10px;
    -webkit-animation: rotate3d 4s linear infinite;
    animation: rotate3d 4s linear infinite;
    opacity: 0.6;
  }
  .animate-shape2 {
    position: absolute;
    right: 57%;
    top: 70%;
    opacity: 0.6;
  }
  .animate-shape3 {
    position: absolute;
    right: 10%;
    top: 10%;
    opacity: 0.6;
  }
  .animate-shape3 > img {
    width: 2em;
  }
  li {
    list-style: none;
  }
  .footer-about-div > p {
    // font-family: "RobotoLight";
    font-size: 1em;
    text-align: justify;
  }
  .footer-heading > h4 {
    // font-family: "RobotoRegular";
    margin-bottom: 30px;
    span {
      margin-right: 1em;
      // float: left;
    }
  }
  .footer-links {
    padding-left: 25px;
    li {
      margin-bottom: 10px;
      position: relative;
      // padding-left: 20px;
      text-align: left;
    }
  }
  .footer-links > li > a {
    font-size: 1em;
    margin-left: 20px;
    color: #333;
    // font-family: "RobotoLight";
  }
  .left-align {
    text-align: left;
  }
}
