/*
 * BusinessAssociates scss File
*/

.business-associates {
    /* propertys here */
    .section-heading > h2 {
        // font-family: "RobotoBold";
        color: #2a028e;
        border-bottom: 1px solid #ccc;
        display: inline-block;
        margin-bottom: 30px;
      }
    .row {
        margin: 2em auto;
    }
    .col {
        // background-color: lightgreen;
        // border: 1px solid black;
        margin: auto;
        padding: 1em;
        // vertical-align: middle;
    }
    .logo-images {
        width: 200px;
    }
    .logo-images-sm {
        width: 120px;
    }
    .logo-images-xsm {
        width: 85px;
    }
}