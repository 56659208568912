/*
 * ErrorPage scss File
*/

#errorPage {
    /* propertys here */
    .page-header {
        height: 100%;
    }
    #footer {
        display: none !important;
    }
}