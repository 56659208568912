.container {
    margin: 2em auto;
    .section-heading > h2 {
      // font-family: "RobotoBold";
      color: #2a028e;
      border-bottom: 1px solid #ccc;
      display: inline-block;
      margin-bottom: 30px;
    }
    .content {
      margin: auto;
    //   img {
    //     width: 100%;
    //   }
    .solutions-image-md {
        width: 80%;
        margin-bottom: 5px;
    }
    .solutions-image-sm {
      width: 60%;
    }
      p {
        text-align: justify;
        span {
          font-weight: bolder;
        }
      }
      .sub-para {
        text-align: justify;
        h4 {
          display: block;
          margin: 1em 0;
          font-size: 20px;
          color: crimson;
          font-weight: bold;
        }
        ul {
          padding-left: 10px;
        }
      }
    }
  }