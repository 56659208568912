/*
 * Products scss File
*/

#Products {
    /* propertys here */
    #ProductsTemplate {
        width: 100%;
        .accordion {
            padding: 1em;
            
        }
    }
    .section-heading > h2 {
        // font-family: "RobotoBold";
        color: #2a028e;
        border-bottom: 1px solid #ccc;
        display: inline-block;
        margin-top: 30px;
    }
    .card-header {
        cursor: pointer;
    }
    .col {
        margin: 1em;
        padding: 1em;
        // border: 1px solid black;
        // border-radius: 5px;
        .card {
            height: 100%;
            &:hover {
                box-shadow: 1px 1px 5px #969696;
            }
        }
        .card-img-top {
            float: left;
            margin: auto;
            height: 250px;
            width: 250px;
        }
        .card-body > * {
            vertical-align: baseline;
        }
    }
}