/*
 * CardWidget scss File
*/

#card-widget {
  /* propertys here */
  .section-heading {
    margin: 0 auto;
    margin-top: 1em;
    h2 {
      color: #2a028e;
      border-bottom: 1px solid #ccc;
      display: inline-block;
      margin-bottom: 30px;
    }
  }
  .section-content {
    padding: 0 10%;
  }
  .card-box {
    border: 1px solid lightgray;
    background-color: #fff;
    height: 80%;
    margin: 1em;
    padding: 2em;
    position: relative;
    span {
      margin-right: 10px;
    }
    &:hover {
      &:before,
      &:after {
        z-index: -1;
        position: absolute;
        content: "";
        bottom: 15px;
        left: 10px;
        width: 50%;
        top: 80%;
        max-width: 300px;
        background: #777;
        -webkit-box-shadow: 0 15px 10px #777;
        -moz-box-shadow: 0 15px 10px #777;
        box-shadow: 0 15px 10px #777;
        -webkit-transform: rotate(-3deg);
        -moz-transform: rotate(-3deg);
        -o-transform: rotate(-3deg);
        -ms-transform: rotate(-3deg);
        transform: rotate(-3deg);
      }
      &:after {
        -webkit-transform: rotate(3deg);
        -moz-transform: rotate(3deg);
        -o-transform: rotate(3deg);
        -ms-transform: rotate(3deg);
        transform: rotate(3deg);
        right: 10px;
        left: auto;
      }
    }
  }
}
