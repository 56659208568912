/*
 * ServicesWidget css File
*/

.ServicesWidget {
    /* propertys here */
    
}
@media only screen and (max-width: 992px) {
    .center-img {
        display: none;
    }
}
.automargin{
    margin: auto;
}
.section-head{
    color: navy;
    font-weight: bold;
}
.section-heading > h2 {
    // font-family: "RobotoBold";
    font-weight: bold;
    font-size: 1.5em;
    color: #2a028e;
    border-bottom: 1px solid #ccc;
    display: inline-block;
    margin-bottom: 30px;
}
.center-img{
    position: absolute;
    z-index: 1;
    left: 75%;
    transform: translate(0%, 67%);
}
.service-bg-h {
    margin-bottom: 30px;
}
.service-box-home {
    padding: 30px;
    background-color: #fff;
    border-radius: 50px;
    transition: all 300ms ease-in-out;
    width: 80%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 1px 1px 10px #ccc, 1px 1px 10px #ccc;
}
.service-box-home::before{
    position: absolute;
    content: "";
    top: -15px;
    left: -40px;
    height: 100px;
    width: 155px;
    border-radius: 90%;
    transform: rotate(-45deg);
    background: #33fccd;
    background: -moz-linear-gradient(top, #33fccd 0%, #3ea1fc 100%);
    background: -webkit-linear-gradient(top, #33fccd 0%,#3ea1fc 100%);
    background: linear-gradient(to bottom, #33fccd 0%,#3ea1fc 100%);
}
.service-box-border {
    padding: 20px;
    border: 2px solid #efefef;
    border-radius: 30px;
    height: 250px;
    display: grid;
    align-content: center;
}
.service-box-border > h3 {
    text-align: center;
    font-family: "RobotoLight";
    color: #333;
    margin-bottom: 10px;
}
.service-bg-h:hover .service-box-border > h3 {
    color: #fff;
    font-weight: bold;
}
.service-bg-h:hover .service-box-border > img {
    filter: brightness(1);
}
.service-box-border > img {
    width: 80px;
    filter: brightness(0);
    margin: 0 auto;
}
.service-bg-h:hover .service-box-home::before {
    display: none;
}
.service-bg-h:nth-child(2) .service-box-home::before {
    background: #f71494;
    background: -moz-linear-gradient(left, #f71494 0%, #5d00b0 100%);
    background: -webkit-linear-gradient(left, #f71494 0%,#5d00b0 100%);
    background: linear-gradient(to right, #f71494 0%,#5d00b0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f71494', endColorstr='#5d00b0',GradientType=1 );
    right: -40px;
    left: unset;
    transform: rotate(45deg);
}
.service-bg-h:nth-child(3) .service-box-home::before {
    background: #fff935;
    background: -moz-linear-gradient(left, #fff935 0%, #fd1b91 100%);
    background: -webkit-linear-gradient(left, #fff935 0%,#fd1b91 100%);
    background: linear-gradient(to right, #fff935 0%,#fd1b91 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff935', endColorstr='#fd1b91',GradientType=1 );
    top: unset;
    bottom: -15px;
    transform: rotate(45deg);
}
.service-bg-h:nth-child(4) .service-box-home::before {
    background: #00daff;
    background: -moz-linear-gradient(left, #00daff 0%, #540fcc 100%);
    background: -webkit-linear-gradient(left, #00daff 0%,#540fcc 100%);
    background: linear-gradient(to right, #00daff 0%,#540fcc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00daff', endColorstr='#540fcc',GradientType=1 );
    top: unset;
    left: unset;
    bottom: -15px;
    right: -40px;
}
.service-bg-h:hover .service-box-home {
    background: #33fccd;
    background: -moz-linear-gradient(left, #33fccd 0%, #3ea1fc 100%);
    background: -webkit-linear-gradient(left, #33fccd 0%,#3ea1fc 100%);
    background: linear-gradient(to right, #33fccd 0%,#3ea1fc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33fccd', endColorstr='#3ea1fc',GradientType=1 );
    transition: all 300ms ease-in-out;
}
.service-bg-h:nth-child(2):hover .service-box-home {
    background: #f71494;
    background: -moz-linear-gradient(left, #f71494 0%, #5d00b0 100%);
    background: -webkit-linear-gradient(left, #f71494 0%,#5d00b0 100%);
    background: linear-gradient(to right, #f71494 0%,#5d00b0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f71494', endColorstr='#5d00b0',GradientType=1 );
}
.service-bg-h:nth-child(3):hover .service-box-home {
    background: #fff935;
    background: -moz-linear-gradient(left, #fff935 0%, #fd1b91 100%);
    background: -webkit-linear-gradient(left, #fff935 0%,#fd1b91 100%);
    background: linear-gradient(to right, #fff935 0%,#fd1b91 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff935', endColorstr='#fd1b91',GradientType=1 );
}
.service-bg-h:nth-child(4):hover .service-box-home {
    background: #00daff;
    background: -moz-linear-gradient(left, #00daff 0%, #540fcc 100%);
    background: -webkit-linear-gradient(left, #00daff 0%,#540fcc 100%);
    background: linear-gradient(to right, #00daff 0%,#540fcc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00daff', endColorstr='#540fcc',GradientType=1 );
}