/*
 * Carousel css File
*/
@keyframes bounceIn {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }
    60% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}
@media only screen and (max-width: 992px) {
    .carousel-caption {        
        p {
            display: none;  
        }
    }
}
@media only screen and (max-width: 768px) {
    .carousel-caption {
        h3 {
            font-size: larger;
        }
    }
}
@media only screen and (max-width: 600px) {
    .carousel-caption {
        h3 {
            font-size: medium;
            position: relative;
            bottom: 1em;
        }
    }
}
.Carousel {
    /* propertys here */
}
.image-set {
    .sub-image {
        margin-top: -50%;
        width: 20%;
        z-index: 15;
        position: relative;
        margin-left: 42%;
        -webkit-animation: bounceIn 8s linear 0s infinite;
        animation: bounceIn 8s linear 0s infinite;
    }

    .carousel-caption {
        right: auto;
        top: 40%;
        h3 {
            font-weight: bold;
        }
    }
}
div .image-set {
    top: 0;
    position: absolute;
    z-index: 0;
}