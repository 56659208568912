/*
 * SolutionsTemplate scss File
*/
@media only screen and (max-width: 575px) {
    #SolutionsTemplate {
        .fire__chart {
            padding-left: 6%;
            width: 90% !important;
        }
        .information-technology__chart {
            padding-left: 3%;
            width: 90% !important;
        }
        .it-security__chart {
            padding-right: 3%;
            width: 90% !important;
        }
        .physical-security__chart {
            padding-right: 4%;
            width: 90% !important;
        }
        .services__chart {
            padding-right: 6%;
            width: 90% !important;
        }
    }
  }
#SolutionsTemplate {
    /* propertys here */
    .chart-container {
        height: auto;
        .flow-chart {
            // position: relative;  
            margin: 4em auto;
            // width: 70%;
        }
        .fire__chart {
            padding-left: 6%;
            width: 80%;
        }
        .information-technology__chart {
            padding-left: 3%;
            width: 80%;            
        }
        .it-security__chart {
            padding-right: 3%;
            width: 50%;            
        }
        .physical-security__chart {
            padding-right: 4%;
            width: 70%;        
        }
        .services__chart {
            padding-right: 6%;
            width: 60%;
        }
    }
}