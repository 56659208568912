/*
 * ConsultationWidget css File
*/
@keyframes rotateme {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 575px) {
  .consultation-img {
      width: 70%;
      margin: auto;
  }
  .heading-para > p {
    text-align: justify !important;
    padding: 0 2em !important;
  }
}
.hidden {
  display: none;
}
#consultation-widget {
  /* propertys here */
  margin-top: 2em;

  .no-margin {
    margin: 0 !important;
  }
  .section-heading > h2 {
    // font-family: "RobotoBold";
    color: #2a028e;
    border-bottom: 1px solid #ccc;
    display: inline-block;
    margin-bottom: 30px;
  }
  .heading-para > p {
    text-align: center;
    // font-family: "RobotoRegular";
    color: #565656;
    line-height: 1.5;
    letter-spacing: 1px;
    font-size: 1em;
    margin-bottom: 20px;
    padding: 0 4em;
  }
  .consultation-img {
    position: relative;
    height: 100%;
    display: grid;
    align-items: center;
  }
  .consultation-img > img {
    max-width: 100%;
    // padding: 4em;
    // margin: 0 2em;
  }
  .animated-img {
    position: absolute;
    z-index: -1;
  }
  .animated-img > img {
    max-width: 100%;
    padding: 4em;
    margin: 0 2em;
    z-index: -1;
  }
  .rotateme {
    -webkit-animation-name: rotateme;
    animation-name: rotateme;
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
  .contact-form {
    margin-top: 15%;
    width: 90%;
  }
  .form-control {
    width: 100%;
    margin: 1em;
  }
  .btn-send {
    background-color: #44ce6f;
    color: #fff;
    border: none;
    // font-family: "RobotoRegular";
    // font-size: 1.6rem;
    padding: 10px 20px;
    border-radius: 5px;
    float: left;
    margin: 0 1em;
  }
}
