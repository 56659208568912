/*
 * Company scss File
*/

#company-page {
    /* propertys here */
    .bg-archived-number {
        color: #fff;
    padding: 50px 15px;
    background-image: url(../../../Assets/imgs/back_blue.png);
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    .count-main > span {
        color: #fff;
        // font-family: "RobotoBold";
        font-size: 3rem;
    }
}
.section-heading {
    margin-top: 1em;
    h2 {
        // font-family: "RobotoBold";
        color: #2a028e;
        border-bottom: 1px solid #ccc;
        display: inline-block;
        margin-bottom: 30px;
    }
}
.about-section {
    background-color: #f7f7f7;
    padding: 2em ;
    text-align: left;
    margin: 0;
    vertical-align: middle;
    & > div{
        margin: auto 0;
        text-align: justify;
        // font-size: 1em;
        &:first-child {
            padding: 5em;
        }
    }
    .image {
        margin: auto 10px;
        width: 100%;
    }
}
.slick-carousel {
    margin: auto;
    width: 90%;
    // background-color: lightgreen;
    .partner-logos {
        margin: auto;
        // padding: 2em;
        max-width: 100%;
        width: 250px;
    }
    .partner-logos-sm {
        margin: auto;
        max-width: 70%;
        width: 180px;
    }
    .partner-logos-xsm {
        margin: auto;
        max-width: 50%;
        width: 100px;
    }
    .slick-slide > div {
        margin-top: 1em;
        display: flex;
        align-items: center;
    }
}
}