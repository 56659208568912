// #page-wrap {
//   text-align: center;

//   /* Prevent sidebar from showing a scrollbar on page */
//   overflow: auto;
// }
@media only screen and (max-width: 1200px) {
    .bm-burger-button {
        display: block !important;
    }
  }
/* Individual item */
.bm-item {
  display: inline-block;
//   position: fixed;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
  position: fixed;
  width: 25px;
  height: 20px;
  left: 36px;
  top: 30px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: whitesmoke;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


// Custom Style
#nav-links {
    ul {
        // border-bottom: 1px solid white;
    }
    width: 100%;
    float: left;
    padding: 0;
    outline: none;
    li {
      margin: 15px 0;
      list-style: none;
      text-align: left;
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      word-break: keep-all;
      ul > li {
          padding-left: 10px;
      }
      a {
        color: #fff;
        text-decoration: none;
        &:hover {
          color: springgreen;
          transition: all 200ms ease-in-out;
        }
        & > svg:hover {
            color: springgreen !important;
            transition: all 200ms ease-in-out;
          }
      }
    }
    .hidden-menu {
        padding-left: 10px;
    }
  }